import React, { createContext, useContext, useEffect, useState } from "react";
import { UserDetailsResponse } from "../../utils/get_user_details";
import { getUserDetails } from "../../utils/get_user_details";
import { getUserOrganisations } from "../../utils/user_organisations";
import { getUserCategories } from "../../utils/user_categories";
import { getProfilePicture } from "../../utils/download_file";

interface UserDetailsContextType {
  userDetails: UserDetailsResponse;
  setUserDetails: React.Dispatch<React.SetStateAction<UserDetailsResponse>>;
}

export const UserDetailsContext = createContext<UserDetailsContextType | undefined>(undefined);

export const UserDetailsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [userDetails, setUserDetails] = useState<UserDetailsResponse>({
    id: 0,
    first_name: "",
    last_name: "",
    organisation: "",
    email: "",
    role: "",
    phone_number: "",
    photo_id: "",
    user_organisations: [], 
    user_categories: [],
  });

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const userDetailsResponse = await getUserDetails();
        setUserDetails((prev) => ({
          ...prev,
          ...userDetailsResponse
        }));

        try {
          const profilePictureResponse = await getProfilePicture(userDetailsResponse.photo_id);
          setUserDetails((prev) => ({
            ...prev,
            photo_id: profilePictureResponse || ""
          }));
        } catch (err) {
          console.error("Error getting profile picture:", err);
        }
      } catch (err) {
        console.error("Failed to get user details:", err);
      }
    };

    fetchUserDetails();
  }, []);

  useEffect(() => {
    const fetchUserOrganisations = async () => {
      try {
        const response = await getUserOrganisations();
        setUserDetails((prev) => ({
          ...prev,
          user_organisations: response
        }));
      } catch (err) {
        console.error("Failed to get user organisations:", err);
      }
    };
    fetchUserOrganisations();
  }, []);

  useEffect(() => {
    const fetchUserCategories = async () => {
      try {
        const response = await getUserCategories();
        setUserDetails((prev) => ({
          ...prev,
          user_categories: response
        }));
      } catch (err) {
        console.error("Failed to get user categories:", err);
      }
    };

    fetchUserCategories();
  }, []);

  useEffect(() => {

  }, []);

  return (
    <UserDetailsContext.Provider value={{ userDetails, setUserDetails }}>
      {children}
    </UserDetailsContext.Provider>
  );
};

// Custom hook for consuming context
export const useUserDetails = (): UserDetailsContextType => {
  const context = useContext(UserDetailsContext);
  if (context === undefined) {
    throw new Error("useUserDetails must be used within a UserDetailsProvider");
  }
  return context;
};
