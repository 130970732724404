import axios from 'axios';
import Cookies from 'js-cookie';
import { OrganisationResponse } from './get_organisations';
import { CategoryResponse } from './get_categories';
import { apiUrl } from './set_api';


export interface UserDetailsResponse {
    id: number,
    first_name: string,
    last_name: string,
    organisation: string,
    email: string,
    role: string,
    phone_number: string,
    photo_id: string,
    user_organisations: OrganisationResponse[], 
    user_categories: CategoryResponse[]
}

export const getUserDetails = async (): Promise<UserDetailsResponse> => {
    try {
        
        // Retrieve the token from cookies
        const token = Cookies.get('token');

        // Make the request to the FastAPI upload endpoint
        const response = await axios.get(`${apiUrl}/users/user_details`, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${token}`,  // Add the bearer token to the request
            },
            withCredentials: true,  // Ensure cookies are sent with the request if needed
        });

        return response.data;

    } catch (error) {
        console.error('return user details failed:', error);
        throw error;
    }
};

