import axios from 'axios';
import Cookies from 'js-cookie';
import { apiUrl } from './set_api';

export const downloadfile = async (fileName: string, saveFileName: string): Promise<void> => {
    try {
        const token = Cookies.get('token');

        if (!token) {
            throw new Error("User is not authenticated. Token is missing.");
        }

        // Properly encode the fileName
        const encodedFileName = encodeURIComponent(fileName);

        // Make the request to download the file
        const response = await axios.get(`${apiUrl}/files/download_file?file_name=${encodedFileName}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            responseType: 'blob',  // Handle as a Blob for file download
            withCredentials: true,
        });

        // Create a Blob URL and trigger the download with the correct save file name
        const blob = new Blob([response.data], { type: response.headers['content-type'] });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', saveFileName);  // Use the provided save file name with extension
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);  // Clean up the URL

    } catch (error) {
        console.error('Failed to download file:', error);
        throw error;
    }
};

export const getProfilePicture = async (fileName: string): Promise<string> => {
    try {
        const token = Cookies.get('token');

        if (!token) {
            throw new Error("User is not authenticated. Token is missing.");
        }
        
        // Properly encode the fileName
        const encodedFileName = encodeURIComponent(fileName);
        
        if (fileName === '') {
            return '';
        }

        // Make the request to download the file
        const response = await axios.get(`${apiUrl}/files/download_file?file_name=${encodedFileName}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            responseType: 'blob',  // Handle as a Blob for file download
            withCredentials: true,
        });

        // Create a Blob URL for the image
        const blob = new Blob([response.data], { type: response.headers['content-type'] });
        const imageUrl = window.URL.createObjectURL(blob);

        // Return the URL for use as an image source
        return imageUrl;

    } catch (error) {
        console.error('Failed to download file:', error);
        return '';
    }
};